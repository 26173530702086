import { PriceStatus } from "@/common/enum";
import SkuServices from "@/services/Skus/SkuServices";
import { defineComponent } from "vue";
import { setPriceFun } from "./setPrice";
export default defineComponent({
    components: {},
    props: {
        showStock: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    setup(props, { emit }) {
        const { rules, priceRef, setPrice, priceValid, removeValid, handleInputChanged } = setPriceFun(props, { emit });
        const skus = SkuServices.GetSkus();
        const settleStatus = SkuServices.settleStatus;
        const selfSaleStatus = SkuServices.selfSaleStatus;
        return {
            priceRef,
            setPrice,
            rules,
            skus,
            PriceStatus,
            removeValid,
            priceValid,
            handleInputChanged,
            settleStatus,
            selfSaleStatus,
        };
    },
});
