import { Section } from "@/services/Section/Section";
import ShopService from "@/services/Shop/ShopService";
import SectionService from "@/services/Section/SectionService";
import { reactive, ref, toRaw } from "vue";
import { BatchAddSection, BatchDelSection } from "@/api/GoShopApi";
import { ElMessage } from "element-plus";
export default function BatchEdit() {
    const selChannel = ref('');
    const sections = reactive(new Array());
    const selectShop = ShopService.GetCurrentShop();
    const selectSection = reactive(new Section());
    const list = toRaw(sections);
    const params = [1, 34, 56];
    const sectionId = 29;
    const initSections = async () => {
        const res = await SectionService.GetAllSectionsByShopId(selectShop.id || 0);
        sections.splice(0, sections.length);
        for (let i = 0; i < res.length; i++) {
            sections.push(res[i]);
        }
        console.log(sections);
        console.log(list);
    };
    const BatchSectionApiMap = {
        'add': BatchAddSection,
        'del': BatchDelSection
    };
    const generateProductList = (type, productList) => {
        // 当前选中的频道文本
        const selChannelNameStr = sections.find(item => item.id === Number(selChannel.value)).name;
        let productListParam = [];
        if (type === 'add') {
            // productListParam = productList.filter(item => !item.sections || !item.sections.includes(selChannelNameStr)).map(item => String(item.productNo))
            productListParam = productList.map(item => String(item.productNo));
            // if (!productListParam.length) {
            //     ElMessage.warning({
            //         message: '商品已存在频道中，无需重复添加',
            //         type: 'warning',
            //     })
            //     return
            // }
        }
        else {
            // productListParam = productList.filter(item => item.sections && item.sections.includes(selChannelNameStr)).map(item => String(item.productNo))
            productListParam = productList.map(item => String(item.productNo));
            // if (!productListParam.length) {
            //     ElMessage.warning({
            //         message: '商品未在频道中，无需选出',
            //         type: 'warning',
            //     })
            //     return
            // }
        }
        return productListParam;
    };
    const operatorSection = async (type, productList, cb) => {
        // 遍历当前选中商品中所有未包含 当前频道 的商品id
        const productNoList = generateProductList(type, productList);
        // console.log(2222, Number(selChannel.value), selChannelNameStr, noContainersProducts);
        const res = await BatchSectionApiMap[type]({
            productNoList, sectionId: selChannel.value
        });
        if (res.isSuccess) {
            ElMessage.success({
                // message: type === 'add' ? '添加成功' : '移除成功',
                message: '操作成功',
            });
            cb();
        }
    };
    return {
        selChannel,
        sections,
        initSections,
        list,
        operatorSection
    };
}
