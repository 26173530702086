import { GetUsableCouponList } from '@/api/CouponApi';
import { ShopProSaleStatus } from '@/common/enum';
import { FormatterDateTime, TranslateCouponObtainWay, TranslateBranchStatus, } from '@/common/formatter';
import { ElMessage } from 'element-plus';
import { defineComponent, onMounted, reactive, ref } from 'vue';
import CreateCoupon from '@/views/Marketing/Coupon/Management/ManagementDialog/CreateCoupon.vue';
export default defineComponent({
    components: {
        CreateCoupon,
    },
    props: {
        dialogCouponArgs: {
            type: Object,
            default() {
                return {
                    id: '',
                    productNo: '',
                    name: '',
                };
            },
        },
    },
    setup(props) {
        const couponDialogArgs = reactive({
            topIdName: '关联商品编号：',
            topShopName: '商品名称：',
            isFromProduct: true,
            productRestrictDetail: '',
            poductName: '',
        });
        const query = reactive({
            StoreProductSelectionsId: '',
            pageSize: 10,
            pageNum: 1,
            total: 1,
        });
        onMounted(() => {
            query.StoreProductSelectionsId = props.dialogCouponArgs.id;
            couponDialogArgs.productRestrictDetail = props.dialogCouponArgs.productNo;
            couponDialogArgs.poductName = props.dialogCouponArgs.name;
            initUseableCouponList();
        });
        const couponDlg = ref(false);
        const couponList = reactive(new Array());
        const handleSizeChange = (val) => {
            query.pageNum = 1;
            query.pageSize = val;
            initUseableCouponList();
        };
        const handleCurrentChange = (val) => {
            query.pageNum = val;
            initUseableCouponList();
        };
        const finishCreateCoupon = () => {
            couponDlg.value = false;
            initUseableCouponList();
        };
        const initUseableCouponList = () => {
            couponList.splice(0, couponList.length);
            GetUsableCouponList(query).then((res) => {
                res.resultData.list.forEach((i) => {
                    couponList.push(i);
                });
                query.total = res.resultData.total;
            });
        };
        const createCoupon = () => {
            if (props.dialogCouponArgs.saleStatus != ShopProSaleStatus.Saleing) {
                ElMessage.warning({
                    message: '该商品不在售卖中，不可以创建优惠券呢~',
                    type: 'warning',
                });
                return;
            }
            couponDlg.value = true;
        };
        return {
            couponDlg,
            query,
            couponList,
            couponDialogArgs,
            FormatterDateTime,
            handleSizeChange,
            handleCurrentChange,
            finishCreateCoupon,
            createCoupon,
            TranslateCouponObtainWay,
            TranslateBranchStatus,
        };
    },
});
