import { FormatterDateTime, TranslatePublishStatus } from "@/common/formatter";
import CategoryService from "@/services/Goods/CategoryService";
export function formatPublishState(row) {
    return TranslatePublishStatus(row.publishStatus);
}
export function formatSaleDateTime(row) {
    const satrtTime = FormatterDateTime(row.startTime, "yyyy-MM-dd hh:mm:ss");
    const endTime = FormatterDateTime(row.endTime, "yyyy-MM-dd hh:mm:ss");
    return satrtTime + "-" + endTime;
}
export function formatLastModifyTime(row) {
    return FormatterDateTime(row.updateTime, "yyyy-MM-dd hh:mm:ss");
}
export function formatGoodsCate(row) {
    const res = CategoryService.GetCatagoryNameById(row.productCategoryId);
    return res;
}
