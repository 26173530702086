import { EditSellSkuData, EditSkuData, GetProductPriceSku, GetProductSku } from "@/api/GoodsApi";
import { EditPriceType, PriceStatus, SkuEnableStatus } from "@/common/enum";
import { TranslateEditPriceType } from "@/common/formatter";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { confirm } from "@/common/utils";
import SkuServices from "@/services/Skus/SkuServices";
import SpecService from "@/services/Specs/SpecServices";
import eventBus from "@/utils/eventBus";
import { onSkuEdited } from "@/utils/eventBusConst";
export class EditSKuPrice {
}
export default function EditSkuPrice() {
    const showSkuDialog = ref(false);
    let curRowInfo = reactive(new EditSKuPrice());
    const showPriceSwitch = ref(false);
    const isEditStock = ref(false); // 统一定价-库存
    const skuDlgTitle = ref("");
    const editPriceBtnLoading = ref(false);
    const productNo = ref('');
    const editBtnLoading = ref(false);
    const curSettleStatus = ref(PriceStatus.open); // 商品分销自营状态
    const editSource = ref(1);
    const editSkuPriceBtn = (s, title) => {
        const cloumn = [{ value: 'originalPrice', title: '市场价', disabled: s.source == 2 }];
        curSettleStatus.value = s.settleStatus || PriceStatus.open;
        editPriceBtnLoading.value = true;
        productNo.value = s.productNo;
        editSource.value = s.source || 1;
        isEditStock.value = false;
        SkuServices.SetStatus(s.settleStatus, s.selfSaleStatus);
        if (s.source == 1) {
            if (s.settleStatus == PriceStatus.open) {
                cloumn.push({ value: 'price', title: '分销成本价' });
            }
            if (s.selfSaleStatus == PriceStatus.open) {
                cloumn.push({ value: 'selfSalePrice', title: '自营销售价' });
            }
            getSelfSaleSku(s, cloumn, title);
        }
        if (s.source == 2) {
            cloumn.push({ value: 'platformPricingPrice', title: '平台成本价', disabled: true });
            cloumn.push({ value: 'selfSalePrice', title: '销售价' });
            showPriceSwitch.value = false;
            getUnSelfSaleSku(s, cloumn, title);
        }
    };
    // 编辑库存
    const editSkuStockBtn = (s) => {
        isEditStock.value = true;
        editPriceBtnLoading.value = true;
        productNo.value = s.productNo;
        editSource.value = s.source || 1;
        curSettleStatus.value = s.settleStatus || PriceStatus.open;
        const cloumn = [{ value: 'stock', title: '库存', disabled: false }];
        if (s.settleStatus == PriceStatus.open) {
            cloumn.push({ value: 'price', title: '分销成本价', disabled: true });
        }
        if (s.selfSaleStatus == PriceStatus.open) {
            cloumn.push({ value: 'selfSalePrice', title: '自营销售价', disabled: true });
        }
        getSelfSaleSku(s, cloumn, '库存');
    };
    // 自营商品skulist
    const getSelfSaleSku = (s, cloumn, title) => {
        GetProductSku({ productNo: s.productNo }).then((res) => {
            editPriceBtnLoading.value = false;
            SkuServices.UpdateColumns(cloumn);
            skuDlgTitle.value = title;
            showSkuDialog.value = true;
            curRowInfo = JSON.parse(JSON.stringify(res.resultData));
            SkuServices.settleStatus.value = res.resultData.settleStatus;
            SkuServices.selfSaleStatus.value = res.resultData.selfSaleStatus;
            SkuServices.InitSkusByList(res.resultData.skuList.filter((i) => { return i.enableStatus == SkuEnableStatus.Normal; }));
            SpecService.InitSpecsList(res.resultData.specList);
        }).catch(() => {
            editPriceBtnLoading.value = false;
        });
    };
    // 分销商品skulist
    const getUnSelfSaleSku = (s, cloumn, title) => {
        GetProductPriceSku({ productNo: s.productNo }).then((res) => {
            editPriceBtnLoading.value = false;
            SkuServices.UpdateColumns(cloumn);
            skuDlgTitle.value = title;
            showSkuDialog.value = true;
            curRowInfo = JSON.parse(JSON.stringify(res.resultData));
            SkuServices.settleStatus.value = res.resultData.settleStatus;
            SkuServices.selfSaleStatus.value = res.resultData.selfSaleStatus;
            SkuServices.InitSkusByList(res.resultData.skuList.filter((i) => { return i.enableStatus == SkuEnableStatus.Normal; }));
            SpecService.InitSpecsList(res.resultData.specList);
        }).catch(() => {
            editPriceBtnLoading.value = false;
        });
    };
    // 空价格处理
    const fillPrice = () => {
        return SkuServices.GetSkus().map((i) => {
            i.price = i.price || 0;
            i.selfSalePrice = i.selfSalePrice || 0;
            return i;
        });
    };
    const setSkuPrice = async (editPriceType) => {
        const toast = ref('');
        // 2-开 分销成本价大于0
        if (SkuServices.settleStatus.value == PriceStatus.open && editPriceType.includes(EditPriceType.price)) {
            const skus = JSON.parse(JSON.stringify(SkuServices.GetSkus()));
            for (let i = 0; i < skus.length; i++) {
                const str = TranslateEditPriceType(EditPriceType.price);
                if (curRowInfo.skuList[i][str] < skus[i][str]) {
                    await confirm('分销成本价有所增加，售卖该商品的店铺需要重新上架才能继续售卖，是否确定调整？', true);
                    toast.value = "分销成本价调整成功，已通知售卖该商品的店铺";
                    break;
                }
            }
        }
        const data = {
            productNo: productNo.value,
            selfSaleStatus: SkuServices.selfSaleStatus.value,
            settleStatus: SkuServices.settleStatus.value,
            skuList: fillPrice(),
            type: editPriceType.includes(EditPriceType.stock) ? 2 : 1
        };
        editBtnLoading.value = true;
        if (editSource.value == 1) {
            editSelfProPrice(data, editPriceType);
        }
        else {
            editUnSelfProPrice(data);
        }
    };
    // 编辑自营商品价格库存
    const editSelfProPrice = (data, editPriceType) => {
        EditSkuData(data).then((res) => {
            showSkuDialog.value = false;
            editBtnLoading.value = false;
            if (res.isSuccess) {
                eventBus.emit(onSkuEdited);
                // 市场价 - 市场价不能小于0
                if (editPriceType.includes(EditPriceType.originalPrice) || editPriceType.includes(EditPriceType.price)) {
                    ElMessage.success({
                        message: "价格调整成功，已通知售卖该商品的店铺",
                        type: "success",
                    });
                    return;
                }
                // 自营销售价大于1
                if (editPriceType.includes(EditPriceType.selfSalePrice)) {
                    ElMessage.success("自营销售价调整成功");
                    return;
                }
                // 修改库存 - 库存不能小于0
                if (editPriceType.includes(EditPriceType.stock)) {
                    ElMessage.success({
                        message: "库存调整成功，已通知售卖该商品的店铺",
                        type: "success",
                    });
                }
            }
        }).catch(() => {
            editBtnLoading.value = false;
        });
    };
    // 编辑分销商品价格
    const editUnSelfProPrice = (data) => {
        EditSellSkuData(data).then((res) => {
            showSkuDialog.value = false;
            editBtnLoading.value = false;
            if (res.isSuccess) {
                eventBus.emit(onSkuEdited);
            }
        }).catch(() => {
            editBtnLoading.value = false;
        });
    };
    return {
        showSkuDialog,
        curRowInfo,
        showPriceSwitch,
        isEditStock,
        skuDlgTitle,
        editSkuPriceBtn,
        editSkuStockBtn,
        setSkuPrice,
        editPriceBtnLoading,
        editBtnLoading,
        curSettleStatus,
        editSource,
    };
}
