import { defineComponent, reactive, ref } from 'vue';
import CommonSetPrice from '@/components/sku/setPrice/setPrice.vue';
import { ElMessage } from 'element-plus';
import { SkuItem } from '@/services/Skus/SkuModel';
export default defineComponent({
    props: {
        source: {
            type: Number,
            default() {
                return 1;
            },
        },
        isEditStock: {
            // 是否是编辑库存
            type: Boolean,
            default() {
                return false;
            },
        },
        title: {
            type: String,
            default() {
                return '';
            },
        },
    },
    components: { CommonSetPrice },
    emits: ['click', 'cancel', 'onInputChanged'],
    setup(props, { emit }) {
        const skuDialog = ref(true);
        const setPriceRef = ref();
        const comPriceRef = ref();
        const priceInfo = reactive(new SkuItem());
        const rules = {
            selfSalePrice: [
                {
                    validator: () => {
                        return priceInfo.selfSalePrice > 0;
                    },
                    required: true,
                    message: `销售价不能小于0`,
                    trigger: 'blur',
                },
            ],
            stock: [
                {
                    validator: () => {
                        return priceInfo.stock > 0;
                    },
                    required: true,
                    message: `库存不能小于0`,
                    trigger: 'blur',
                },
            ],
        };
        const cancelPrice = () => {
            setPriceRef.value.resetFields();
            comPriceRef.value && comPriceRef.value.removeValid();
            emit('cancel');
        };
        const submitPrice = () => {
            if (!props.isEditStock && props.source == 1) {
                comPriceRef.value
                    .priceValid()
                    .then((val) => {
                    emit('click', val);
                })
                    .catch(() => {
                    ElMessage.error('请正确输入定价数值');
                });
            }
            else {
                setPriceRef.value.validate((valid) => {
                    if (valid) {
                        emit('click', priceInfo);
                    }
                    else {
                        return false;
                    }
                });
            }
        };
        const onPriceChanged = (propName, index, val) => {
            priceInfo[propName] = val;
            emit('onInputChanged', propName, index, val);
        };
        return {
            skuDialog,
            setPriceRef,
            comPriceRef,
            priceInfo,
            rules,
            cancelPrice,
            submitPrice,
            onPriceChanged,
        };
    },
});
