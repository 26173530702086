import { ChangeSortWeight } from "@/api/GoShopApi";
import { ElMessage } from "element-plus";
import { ref } from "vue";
export default function EditSortWeight(datas, queryData) {
    const modifySortWeight = ref(0);
    const openSortWeight = (row) => {
        datas.forEach((i) => {
            i.sortWeightPopover = false;
        });
        row.sortWeightPopover = true;
        modifySortWeight.value = row.sortWeight || 0;
    };
    const editSortWeightSubmit = async (row) => {
        if (modifySortWeight.value < 0 || modifySortWeight.value > 999) {
            ElMessage.error("权重值不能大于999，不能小于0");
            throw "";
        }
        const res = await ChangeSortWeight({ id: row.id, sortWeight: modifySortWeight.value });
        if (res.isSuccess) {
            row.sortWeightPopover = false;
            ElMessage.success("调整成功");
        }
    };
    const goEditSortWeight = async (row) => {
        try {
            await editSortWeightSubmit(row);
            queryData();
        }
        catch (e) {
            console.log(e);
        }
    };
    return {
        modifySortWeight,
        openSortWeight,
        goEditSortWeight
    };
}
