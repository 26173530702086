import { reactive, ref } from "vue";
import { SkuItem } from "@/services/Skus/SkuModel";
export function setPriceFun(props, { emit }) {
    const priceRef = ref(null);
    const setPrice = reactive(new SkuItem());
    const rules = {
        originalPrice: [
            {
                validator: () => {
                    return setPrice.originalPrice > 0;
                },
                required: true,
                message: '市场价最低不能低于0.01元',
                trigger: 'blur',
            },
        ],
        price: [
            {
                validator: () => {
                    return setPrice.price > 0;
                },
                required: true,
                message: '商品销售价格不符合规范',
                trigger: 'blur',
            },
        ],
        unPrice: [
            {
                require: false,
            },
        ],
        selfSalePrice: [
            {
                validator: () => {
                    return setPrice.selfSalePrice > 0;
                },
                required: true,
                message: '自营销售价格不符合规范',
                trigger: 'blur',
            },
        ],
        unSelfSalePrice: [
            {
                require: false,
            },
        ],
    };
    const priceValid = () => {
        return new Promise((reslove, reject) => {
            priceRef.value.validate((valid) => {
                if (valid) {
                    reslove(setPrice);
                }
                else {
                    reject();
                }
            });
        });
    };
    const removeValid = () => {
        priceRef.value.resetFields();
    };
    const handleInputChanged = (prop, val) => {
        emit('onInputChanged', prop, 0, val);
    };
    return {
        rules,
        setPrice,
        priceRef,
        priceValid,
        removeValid,
        handleInputChanged,
    };
}
