import { GetGoodsList } from "@/api/GoodsApi";
import { PublishStatus, SaleStatus, StockStatus } from "@/common/enum";
import CategoryService from "@/services/Goods/CategoryService";
import { computed, onMounted, reactive, ref } from "vue";
import TableSortClick from "@/views/Common/SortTable";
import { ScrollToTop } from "@/common/utils";
import { FormatterDateTime } from "@/common/formatter";
import eventBus from "@/utils/eventBus";
import { onSkuEdited } from "@/utils/eventBusConst";
import SkuServices from "@/services/Skus/SkuServices";
import ShopService from "@/services/Shop/ShopService";
import router from "@/router";
const { setCurrentSort, headerSortClick } = TableSortClick();
setCurrentSort({
    prop: "updatedTime",
});
export default function MineQuery() {
    const showDownloadDialog = ref(false);
    const datas = reactive(new Array());
    const totalCount = ref(0);
    const allTypes = reactive(new Array());
    const loading = ref(false);
    const updatedTime = ref("");
    const windowWidth = ref(window.innerWidth);
    const showChanelDialog = ref(false);
    // const row = ref("")
    // const multipleSelection = ref([])
    // const handleSelectList = ref([])
    const params = reactive({
        merchantStoreId: ShopService.GetCurrentShop().id,
        pageNum: 1,
        pageSize: 10,
        endTime: '',
        startTime: '',
        name: '',
        originName: '',
        productCategoryId: '',
        productNo: '',
        saleStatus: SaleStatus.None,
        publishStatus: PublishStatus.None,
        source: '',
        sortWay: '',
        stockStatus: StockStatus.None,
    });
    onMounted(() => {
        params.productNo = router.currentRoute.value.query.productNo || '';
    });
    function initAllType() {
        const result = CategoryService.GetAllCatagory();
        allTypes.splice(0, allTypes.length);
        result.forEach((item) => {
            allTypes.push(item);
        });
    }
    async function queryData() {
        loading.value = true;
        params.name = params.originName.replace(/\+/g, '%2B');
        const result = await GetGoodsList(params);
        loading.value = false;
        if (result.isSuccess) {
            totalCount.value = result.resultData.total;
            datas.splice(0, datas.length);
            result.resultData.list.forEach((item) => {
                datas.push(item);
            });
            ScrollToTop();
        }
    }
    eventBus.on(onSkuEdited, () => {
        queryData();
    });
    const headerClick = async (column) => {
        const sortValue = {
            'stock': { ascending: 3, descending: 4 },
            'sortWeight': { ascending: 5, descending: 6 },
        };
        if (await headerSortClick(column)) {
            if (!column.order) {
                params.sortWay = '';
            }
            params.sortWay = sortValue[column.prop][column.order];
            params.pageNum = 1;
            queryData();
        }
    };
    const changeTime = (val) => {
        params.startTime = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || '';
        params.endTime = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || '';
    };
    const expDeliver = () => {
        showDownloadDialog.value = true;
    };
    const windowResize = () => {
        windowWidth.value = window.innerWidth;
    };
    const dialogWidth = computed(() => {
        const tableWidth = SkuServices.GetTableWidth() + 50;
        if (windowWidth.value < tableWidth) {
            return windowWidth.value - 100;
        }
        return tableWidth + 42;
    });
    // const handleSelectionChange = (val:any) => {
    //     showChanelDialog.value = true; 
    //     multipleSelection.value= val
    //     const handleSelectList=JSON.parse(JSON.stringify(multipleSelection.value))
    //     console.log(handleSelectList,'mmmm')
    //     const productNames = handleSelectList.map((item:any)=>{return item.name})
    //     console.log(productNames,'11111')
    // }
    // const getRowKeys = (row:any) => {
    //     return row
    // }
    return {
        params,
        totalCount,
        updatedTime,
        queryData,
        initAllType,
        allTypes,
        datas,
        loading,
        headerClick,
        changeTime,
        expDeliver,
        showDownloadDialog,
        windowResize,
        dialogWidth,
        showChanelDialog,
    };
}
