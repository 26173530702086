import { GoodsResponse, ModifyGoodsBaseInfo, ModifyGoodsBaseInfoArgs } from "@/api/GoodsApi";
import { FormatterDateTime } from "@/common/formatter";
import { ModifyType, PublishStatus, ShopProSaleStatus } from "@/common/enum";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import router from "@/router/index";
import { ChangeShopProdSaleStatus } from "@/api/GoShopApi";
export default function MineEdit(loading, datas, queryData) {
    const modifyTimeRange = ref(new Array());
    const showChanelDialog = ref(false);
    const multipleSelection = ref([]);
    const handleSelectList = reactive([]);
    const productNames = reactive({
        productNameList: [],
    });
    function editTime(s) {
        datas.forEach((i) => {
            i.timePopover = false;
        });
        s.timePopover = true;
        if (modifyTimeRange.value.length > 0) {
            modifyTimeRange.value.splice(0, 1, '');
            modifyTimeRange.value.splice(1, 1, '');
        }
        else {
            modifyTimeRange.value.push('');
            modifyTimeRange.value.push('');
        }
        if (s.startTime) {
            modifyTimeRange.value.splice(0, 1, new Date(s.startTime));
        }
        if (s.endTime) {
            modifyTimeRange.value.splice(1, 1, new Date(s.endTime));
        }
    }
    async function editTimeSubmit(val, row) {
        const now = new Date().getTime();
        const inRange = val[0].getTime() <= now && now <= val[1].getTime();
        if (!inRange) {
            await confirm('销售时间调整后，正在售卖该商品的店铺将停止售卖该商品，是否确定调整？', true, '关闭自营模式');
        }
        const args = new ModifyGoodsBaseInfoArgs();
        args.productNo = row.productNo;
        args.startTime = FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss");
        args.endTime = FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss");
        args.type = ModifyType.Time;
        loading.value = true;
        const result = await ModifyGoodsBaseInfo(args);
        loading.value = false;
        if (result.isSuccess) {
            row.timePopover = false;
            row.startTime = args.startTime;
            row.endTime = args.endTime;
            modifyTimeRange.value.splice(0, modifyTimeRange.value.length);
            ElMessage.success("销售时间调整成功，已通知售卖该商品的店铺");
            queryData();
        }
    }
    // 上架
    async function publishGoods(row) {
        const args = new ModifyGoodsBaseInfoArgs();
        args.productNo = row.productNo;
        args.publishStatus = PublishStatus.Published;
        args.type = ModifyType.Publish;
        loading.value = true;
        const result = await ModifyGoodsBaseInfo(args);
        loading.value = false;
        if (result.isSuccess) {
            row.publishStatus = PublishStatus.Published;
            ElMessage.success({
                message: "上架成功",
                type: "success",
            });
            queryData();
        }
    }
    // 下架
    async function unPublishGoods(row) {
        await confirm('商品下架后，正在售卖该商品的店铺将停止售卖该商品，是否确定调整？', true);
        const args = new ModifyGoodsBaseInfoArgs();
        args.productNo = row.productNo;
        args.publishStatus = PublishStatus.UnPublished;
        args.type = ModifyType.UnPublish;
        loading.value = true;
        const result = await ModifyGoodsBaseInfo(args);
        loading.value = false;
        if (result.isSuccess) {
            row.publishStatus = PublishStatus.UnPublished;
            ElMessage.success({
                message: "下架成功",
                type: "success",
            });
            queryData();
        }
    }
    // 编辑
    function gotoEdit(row) {
        router.push({
            path: "/goods/edit/" + row.productNo,
        });
    }
    const goDetailVisible = ref(false);
    const product = reactive(new GoodsResponse());
    function goDetail(row) {
        goDetailVisible.value = true;
        setProduct(row);
    }
    function confirm(con, showCancelButton, tit) {
        return ElMessageBox.confirm(con, tit || '提醒', {
            showCancelButton: showCancelButton,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        });
    }
    const couponVisible = ref(false);
    const goCoupon = (row) => {
        couponVisible.value = true;
        setProduct(row);
    };
    const editSectionDlg = ref(false);
    const goSection = (row) => {
        editSectionDlg.value = true;
        setProduct(row);
    };
    //批量调整频道
    const chanelDialogVisible = ref(false);
    const goBatchSection = () => {
        chanelDialogVisible.value = true;
    };
    const handleSelectionChange = (val) => {
        showChanelDialog.value = true;
        multipleSelection.value = val;
        const handleSelectList = JSON.parse(JSON.stringify(multipleSelection.value));
        // productNames.productNameList = handleSelectList.map((item:any)=>{return item.name})
        productNames.productNameList = handleSelectList;
    };
    const getRowKeys = (row) => {
        return row;
    };
    const setProduct = (row) => {
        product.productNo = row.productNo;
        product.name = row.name;
        product.id = row.id;
        product.saleStatus = row.saleStatus;
    };
    const goCopy = (row) => {
        window.open("/#/goods/copy/" + row.productNo);
    };
    const gotoStopSale = async (row) => {
        await confirm('确定暂停售卖该商品吗？', true, '确认');
        const res = await ChangeShopProdSaleStatus({ productNo: row.productNo, saleStatus: ShopProSaleStatus.PauseSale });
        if (res.isSuccess) {
            ElMessage.success("暂停销售成功");
            queryData();
        }
    };
    const goSale = async (val) => {
        await ElMessageBox.alert("确定继续销售该商品吗？", "确认");
        const res = await ChangeShopProdSaleStatus({ productNo: val.productNo, saleStatus: ShopProSaleStatus.Saleing });
        if (res.isSuccess) {
            ElMessage.success("继续销售成功");
            queryData();
        }
    };
    return {
        editTimeSubmit,
        unPublishGoods,
        publishGoods,
        gotoEdit,
        goDetail,
        goDetailVisible,
        goCoupon,
        couponVisible,
        product,
        goSection,
        goCopy,
        editSectionDlg,
        gotoStopSale,
        goSale,
        editTime,
        modifyTimeRange,
        goBatchSection,
        showChanelDialog,
        chanelDialogVisible,
        handleSelectionChange,
        getRowKeys,
        productNames,
    };
}
