import { AddShopSectionRelation, DelShopSectionRelation, GetSectionByProductNo, } from "@/api/GoShopApi";
import { ShelfStatus } from "@/common/enum";
import ShopService from "@/services/Shop/ShopService";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, reactive } from "vue";
export default defineComponent({
    props: {
        product: { type: Object, required: true },
    },
    setup(props) {
        const sections = reactive(new Array());
        const selectedShop = ShopService.GetCurrentShop();
        onMounted(() => {
            getData();
        });
        const getData = () => {
            sections.splice(0, sections.length);
            GetSectionByProductNo({
                productNo: props.product.productNo,
                merchantStoreId: selectedShop.id,
            }).then((res) => {
                res.resultData.forEach((item) => {
                    sections.push({
                        id: item.sectionId,
                        name: item.name,
                        relationId: item.relationId,
                        status: item.relationId
                            ? ShelfStatus.Shelfed
                            : ShelfStatus.UnShelfed,
                    });
                });
            });
        };
        const toShelf = (val) => {
            AddShopSectionRelation({
                sectionId: val.id,
                productNo: props.product.productNo,
            }).then((res) => {
                if (res.isSuccess) {
                    ElMessage.success("上架成功");
                    getData();
                }
            });
        };
        const unShelf = (val) => {
            DelShopSectionRelation(val.relationId).then((res) => {
                if (res.isSuccess) {
                    ElMessage.success("下架成功");
                    getData();
                }
            });
        };
        return {
            sections,
            ShelfStatus,
            toShelf,
            unShelf,
        };
    },
});
